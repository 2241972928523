import React from "react";
import { Backdrop, Button, Fade, Modal, TextField } from "@material-ui/core";

const ColumnNameModal = ({open, useStyles, setOpen, 
    onAddColumn}) => {
  const classes = useStyles();
  const [columnName, setColumnName] = React.useState("");

  return (
    <Modal
      aria-labelledby="modal-title"
      className={classes.modal}
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <h2 id="modal-title">Add column</h2>
          <TextField
            type="text"
            placeholder="Enter column name"
            onChange={(event) => {
              setColumnName(event.target.value);
            }}
          />
          <div className={classes.buttonRoot}>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="primary"
                onClick={()=>{
                  onAddColumn(columnName);
                  setOpen(false);
                }}
              >
                Add column
              </Button>
            </div>
            <div className={classes.wrapper}>
              <Button
                variant="contained"
                color="secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}

export default ColumnNameModal;
