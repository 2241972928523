import React, { Component } from "react";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { handler } from "../../utils";
import { DoublePlot } from "csv-viewer-react";
import Double from "csv-viewer-react/dist/components/templates/Double";
import Loading from "../templates/Loading";

const API_VERSION = process.env.REACT_APP_API_VERSION

class ScatterHDFPlots extends Component {
  constructor(props) {
    super(props)
    this.ax = props.axios
    let x_idx = -1
    let y_idx = -1
    if(this.props.hdfAPI){
      this.ax.get(
        encodeURI("/label-hdf/v" + API_VERSION + "/" + this.props.currentProject),
        {params: {"hdf_dir": this.props.hdfAPI}}
      )
      .then(info => {
        const file_data = info.data.file_info
        const label = info.data.label_list
        x_idx = 0
        y_idx = label[0].length>1 ? 1 : 0
        const xlabel = label[0][x_idx]
        const ylabel = label[0][y_idx]
        this.getHDF(xlabel, ylabel, file_data[0]).then(res => {
          this.setState({ data: res, file_data: file_data, labels: label, xLabel: x_idx, yLabel: y_idx, selectedData: 0})
        })
      }).catch(error => handler(error));
    }else{
      this.getHDF({}, {}, {}).then(res => {
        this.setState({ data: res, file_data: [], labels: [], xLabel: x_idx, yLabel: y_idx, selectedData: -1})
      })
    }
  }

  getHDF = async (xlabel, ylabel, file_data) => {
    let response = {}
    if (this.props.hdfAPI &&Object.keys(xlabel).length>0 && Object.keys(ylabel).length>0 && Object.keys(file_data).length>0) {
      response = await this.ax.post(
        encodeURI("/scatter-hdf/v" + API_VERSION +"/" + this.props.currentProject),
        {
          "directory": this.props.hdfAPI, 
          "x_info": file_data,
          "y_info": file_data,
          "x_label": xlabel,
          "y_label": ylabel,
        }
      ).catch(error => handler(error));
    }
    if (!response?.data) return {}
    console.log("hdf data : ", response.data.data)
    return response.data.data
  }
  handleSelectedData = async event => {
    const index = event.target.value
    const x_idx = 0
    const y_idx = this.state.labels[index].length>1 ? 1 : 0
    const xlabel = this.state.labels[index][x_idx]
    const ylabel = this.state.labels[index][y_idx]
    const data = await this.getHDF(xlabel, ylabel, this.state.file_data[index])
    this.setState({
      data,
      selectedData: index,
      xLabel: x_idx,
      yLabel: y_idx,
    })
  }
  handleXLabelChange = async event => {
    const idx = event.target.value
    const label = this.state.labels[this.state.selectedData]
    const data = await this.getHDF(label[idx], label[this.state.yLabel], this.state.file_data[this.state.selectedData])
    console.log("xlabel change")
    this.setState({
      data,
      xLabel: idx
    })
    console.log(this.state)
  }
  handleYLabelChange = async event => {
    const idx = event.target.value
    const label = this.state.labels[this.state.selectedData]
    const data = await this.getHDF(label[this.state.xLabel], label[idx], this.state.file_data[this.state.selectedData])
    console.log("ylabel change")
    this.setState({
      data,
      yLabel: idx
    })
    console.log(this.state)
  }
  render() {
    if (!this.state || !this.state.data) {
      return (
        <Loading/>
      )
    }
    const response = this.state.data
    const data = [];
    const xdata = response.xdata ? response.xdata : [];
    const ydata = response.ydata ? response.ydata : [];
    for (let i = 0; i < xdata.length; i++) {
      const trace = {
        x: xdata[i],
        y: ydata[i],
        mode: "markers",
        type: "scatter",
        name: "",
        xaxis: "x",
        yaxis: "y",
      };
      data.push(trace);
    }
    const layout = {
      width: 440,
      height: 440,
      showlegend: false,
      title: { text: "HDF Scatter Plot" },
      legend: {
        font: { size: 9 },
        orientation: "h",
        y: -0.25,
      },
      hovermode: "closest",
      xaxis: { title: this.state.hdfAPI ? this.state.labels[this.state.selectedData][this.state.xLabel].Label : "" },
      yaxis: { title: this.state.hdfAPI ? this.state.labels[this.state.selectedData][this.state.yLabel].Label : ""},
    };
    const config = {
      responsive: true,
      editable: true,
      scrollZoom: true,
      displaylogo: false,
      modeBarButtonsToRemove: ["select2d", "lasso2d", "zoomIn2d", "zoomOut2d"],
    }
    const options_file_data = this.state.file_data.map( (value, index) => {
      const text = value.file_name +(value.sheet ? " / Sheet " + value.sheet : "") + " (" +value.cycle + ")";
      return (<option key={text} value={index}>{text}</option>)
    })
    const options = this.state.selectedData>=0 ? this.state.labels[this.state.selectedData].map( (value, index) => {
      return (<option key={value.Label} value={index}>{value.Label}</option>)
    }) : []
    return (
      <>
        <Double>
          <FormControl>
            <Select
              native
              value={this.state.selectedData}
              onChange={this.handleSelectedData}
            >
              <option value={""} disabled>File data</option>
              {options_file_data}
            </Select>
          </FormControl>
        </Double>
        <Double>
          <DoublePlot
            data={data}
            layout={layout}
            config={config}
          />
        </Double>
        <Double>
          <FormControl>
            <Select
              native
              value={this.state.xLabel}
              onChange={this.handleXLabelChange}
            >
              <option value={""} disabled>X Label</option>
              {options}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              native
              value={this.state.yLabel}
              onChange={this.handleYLabelChange}
            >
              <option value={""} disabled>Y Label</option>
              {options}
            </Select>
          </FormControl>
        </Double>
      </>
    );
  }
}

export default ScatterHDFPlots;
