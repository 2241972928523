// axiosのエラーはalertで表示し、それ以外のものはthrowするハンドラー
export function handler(error) {
  if (!error.response) throw error;
  const detail = error.response?.data?.detail;
  const statusText = error.response?.statusText || "";
  const detailString =
    typeof detail === 'string' ? detail :
      !!detail ? JSON.stringify(detail) :
      "";
  alert(`${error}: ${statusText}\n${detailString}`);
}

export function formatBytes(bytes, decimals = 2) {
  if (bytes === 0) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}
