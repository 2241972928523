import React from "react";
import ReactMarkdown from "react-markdown"
import remarkGfm from 'remark-gfm'
import "./Markdown.css"

function importAll(r) {
  let files = new Map();
  r.keys().forEach((item, _index) => {
    files.set(item, r(item).default);
  });
  return files;
}

const files = importAll(require.context('../../../../manuals', true, /\.(gif|png|jpe?g|md)$/));
window.files = files;

const transformUri = src => {
  return files.get('./v' + process.env.REACT_APP_API_VERSION + '/' + src);
}

function Image(props) {
  return <img {...props} alt={props.src} style={{maxWidth: '100%'}} />
}

const Manual = () => {
  const [text, setText] = React.useState("");
  React.useEffect(() => {
    fetch(files.get('./v' + process.env.REACT_APP_API_VERSION + '/README.md'))
      .then(r => r.text())
      .then(async r => {
        setText(r);
      });
  }, []);

  return (
    <div
      class="markdown-body"
      style={{paddingLeft: "2rem", paddingRight: "2rem"}}
    >
      <ReactMarkdown
        children={text}
        transformImageUri={transformUri}
        remarkPlugins={[remarkGfm]}
        components={{img: Image}}
      />
    </div>
  );
}

export default Manual;
