import React, { useReducer } from "react";
import axios from "axios";
import { ThemeProvider } from "@material-ui/styles";
import * as styles from "@material-ui/core/styles";
import * as colors from "@material-ui/core/colors";

import Correlation from "./components/pages/Correlation";
import { csvReducer, record,
  LinearRegression, Lasso, Ridge, Histogram, Heatmap, DataViewer,
  GenericTemplate,
} from "csv-viewer-react";
import Import from "./components/import/Import";
import HDFPlots from "./components/pages/HDFPlots";
import ScatterHDFPlots from "./components/pages/ScatterHDFPlots";
import MenuContent from "./components/templates/MenuContent";
import HDFTable from "./components/pages/HDFTable";
import Manual from "./components/pages/Manual";

console.log("API URL", process.env.REACT_APP_API_URL);
console.log("API VERSION", process.env.REACT_APP_API_VERSION);

const ax = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  responseType: 'json',
});

ax.interceptors.request.use(function (config) {
  config.headers.Authorization =
    "Bearer " + localStorage.getItem("token");
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

ax.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response.status === 401) {
      alert("再度ログインが必要です。");
      window.location.href = "/login";
      localStorage.setItem("token", "");
    }
    return Promise.reject(error);
  }
);

const createTheme = process.env.NODE_ENV === 'development' ?
  styles.unstable_createMuiStrictModeTheme :
  styles.createTheme;

const theme = createTheme({
  typography: {
    fontFamily: [
      "Noto Sans JP",
      "Lato",
      "游ゴシック Medium",
      "游ゴシック体",
      "Yu Gothic Medium",
      "YuGothic",
      "ヒラギノ角ゴ ProN",
      "Hiragino Kaku Gothic ProN",
      "メイリオ",
      "Meiryo",
      "ＭＳ Ｐゴシック",
      "MS PGothic",
      "sans-serif",
    ].join(","),
  },
  palette: {
    primary: { main: colors.green[800] }, // テーマの色
    plotBorder: 'tomato',
  },
});

const Main = (props) => {
  const initialCsvState = {
    currentPage: "Import",
    open: true,
    dataFile: null,
    colorFile: null,
    rows: 0,
    cols: 0,
    dataOrg: {},
    categoryMap: {},
    data: {},
    filteredData: {},
    samples: { Name: [] },
    enabledSamples: {},
    xLabel: null,
    yLabel: null,
    skipRecord: [],
    categoryFilter: { purple: true },
    labelFilter: {},
    corr: {},
    currentW: [],
    W: [],
    dataPred: [],
    fScore: "NaN",
    r2Score: "NaN",
    RMSE: "NaN",
    MAE: "NaN",
    midW: {},
    outputW: {},
    inputData: [],
    midData: [],
    outputData: [],
    inputColor: { purple: true },
    midColor: { purple: true },
    outputColor: { purple: true },
    inputLabels: [],
    midLabels: [],
    outputLabels: [],
    threashold: 100,
    isAbs: false,
    isDataChange: false,
    nodeSize: 10,
    nodeInterval: 1.0,
    currentTab: 0,
    fontSize: 10,
    fontSizeHM: 10,
    lineWidth: 1.0,
    modalOpen: false,
    LRNestedOpen: false,
    modalLabel: null,
    history: [],
    ridgeAlpha: 1.0,
    lassoAlpha: 1.0,
    lassoIteration: 100,
    categoryColors: {},
    defaultCategoryColor: "#60bd68"
  };

  const [csvState, dispatch] = useReducer(csvReducer, initialCsvState);

  const [hdfApi, setHdfApi] = React.useState(null);
  const [currentProject, setCurrentProject] =
    React.useState(localStorage.getItem("project"));

  const dName = csvState.dataFile ? csvState.dataFile.name : "Nothing";
  const cName = csvState.colorFile ? csvState.colorFile.name : "Nothing";
  const currentPage = csvState.currentPage;
  const newRecord = record(csvState)
  let content;
  switch (currentPage) {
    case "Correlation":
      content =
        <Correlation
          handleDataChange={(t, file) => dispatch({
            type: 'dataFileChange',
            payload: [t, file]
          })}
          handleColorChange={(t, file) => dispatch({
            type: 'colorFileChange',
            payload: [t, file]
          })}
          dataFiname={dName}
          colorFiname={cName}
          categoryMap={csvState.categoryMap}
          record={newRecord}
          handleColorFilterChange={color => dispatch({
            type: 'categoryFilterChange',
            payload: color
          })}
          reset={() => dispatch({type: 'reset'})}
          handleDataAppendAll={mode => dispatch({
            type: 'dataAppendAll',
            payload: mode
          })}
          handleDataAppend={(label, mode) => dispatch({
            type: 'dataAppend',
            payload: [label, mode]
          })}
          xLabel={csvState.xLabel}
          yLabel={csvState.yLabel}
          handleXLabelChange={label => dispatch({
            type: 'xLabelChange',
            payload: label
          })}
          handleYLabelChange={label => dispatch({
            type: 'yLabelChange',
            payload: label
          })}
          handleDataDelete={label => dispatch({
            type: 'dataDelete',
            payload: label
          })}
          ModalOpen={csvState.modalOpen}
          modalLabel={csvState.modalLabel}
          // handleModalOpenChange={this.handleModalOpenChange}
          userID={localStorage.getItem("userID")}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "Import":
      content =
        <Import
          userID={localStorage.getItem("UserID")}
          userName={localStorage.getItem("UserName")}
          projects={JSON.parse(localStorage.getItem("projects"))}
          record={newRecord}
          handleDataFileChange={(t, file) => dispatch({
            type: 'dataFileChange',
            payload: [t, file]
          })}
          handleHDFAPI={setHdfApi}
          handleProjectChange={setCurrentProject}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "LinearRegression":
      content =
        <LinearRegression
          dispatch={dispatch}
          title="Linear Regression"
          typeForFilename="linearRegressionModel"
          record={newRecord}
          isAbs={csvState.isAbs}
          fontSize={csvState.fontSize}
        />;
      break;
    case "Lasso":
      content =
        <Lasso
          dispatch = {dispatch}
          record={newRecord}
          isAbs={csvState.isAbs}
          fontSize={csvState.fontSize}
          alpha={csvState.lassoAlpha}
        />;
      break;
    case "Ridge":
      content =
        <Ridge
          dispatch = {dispatch}
          record={newRecord}
          isAbs={csvState.isAbs}
          fontSize={csvState.fontSize}
          alpha={csvState.ridgeAlpha}
        />;
      break;
    case "HeatMap":
      content =
        <Heatmap
          record={newRecord}
          fontSize={csvState.fontSizeHM}
        />;
      break;
    case "Histogram":
      content =
        <Histogram
          data={csvState.filteredData}
          labels={Object.keys(csvState.dataOrg)}
        />;
      break;
    case "DataViewer":
      content =
        <DataViewer
          dispatch={dispatch}
          data={csvState.data}
          labelFilter={csvState.labelFilter}
          colorForLabel={label =>
            csvState.categoryColors[csvState.categoryMap[label]]}
          samples={csvState.samples}
          enabledSamples={csvState.enabledSamples}
        />;
      break;
    case "HDFTable":
      content =
        <HDFTable
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "ScatterHDF":
      content =
        <ScatterHDFPlots
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "CV":
      content =
        <HDFPlots
          mode="CV"
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "LSV":
      content =
        <HDFPlots
          mode="LSV"
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "BGSLSV":
      content =
        <HDFPlots
          mode="BGS_LSV"
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "Tafel":
      content =
        <HDFPlots
          mode="Tafel"
          hdfAPI={hdfApi}
          currentProject={currentProject}
          axios={ax}
        />
      break;
    case "Manual":
      content =
        <Manual
        />
      break;
      default:
  }
  const handleCurrentPageChange = React.useCallback(page => {
    dispatch({
      type: 'currentPageChange',
      payload: page
    })
  }, []);
  return (
    <ThemeProvider theme={theme}>
    <GenericTemplate
      mainTitle="PEFC Data Viewer"
      handleCurrentPageChange={handleCurrentPageChange}
      // pageTitle={csvState.currentPage}
      MenuContent={MenuContent}
    >
      {content}
    </GenericTemplate>
    </ThemeProvider>
  )
}

export default Main;
