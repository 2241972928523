import React from "react";
import ReactDOM from "react-dom";
import { Button, Checkbox, Divider, IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Popover, Snackbar, TableCell, TableRow, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import { EnhancedTable, Double } from "csv-viewer-react";
import { handler } from "../../utils";
import Loading from "../templates/Loading";

const API_VERSION = process.env.REACT_APP_API_VERSION;

const MAX_TABLE_ROWS = 1000;

const HDFTable = ({axios, currentProject, hdfAPI, }) => {
  const [fileInfo, setFileInfo] = React.useState([]);
  const [labels, setLabels] = React.useState([]);
  const [currentFileIndex, setCurrentFileIndex] = React.useState(0);
  const [columns, setColumns] = React.useState([]);
  const [rows, setRows] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [newLabels, setNewLabels] = React.useState([]);
  const [selections, setSelections] = React.useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [totalRowCount, setTotalRowCount] = React.useState(0);

  const getColumns = React.useCallback(async (index, fileInfo, labels) => {
    const labelsParam = labels[index].map(label => ({
      "file": fileInfo[index],
      "label": label
    }))
    return await axios.post(
      encodeURI("/get-col/v" + API_VERSION + "/" + currentProject),
      {
        directory: hdfAPI,
        labels: labelsParam
      }
    ).then(response => {
      const data = response.data.data;
      const columns = data.map(col => ({
        label: col.label,
        column: col.column
      }));
      setColumns(columns);

      setHeadCells(columns.map(column => ({
        id: `${column.label}-${column.column}`,
        numeric: true,
        disablePadding: true,
        label: column.label
      })));

      const rowCount = data[0].data[0].length;
      setTotalRowCount(rowCount);
      if (rowCount > MAX_TABLE_ROWS) {
        setSnackbarOpen(true);
      }
      const rows = [...Array(Math.min(rowCount,1000)).keys()].map(j => {
        const pairs = columns.map((column, i) =>
          [`${column.label}-${column.column}`, data[i].data[0][j]]
        ).concat([["key", j]]);
        return Object.fromEntries(pairs);
      })
      setRows(rows);

      return response;
    }
    ).catch(error => handler(error));
  }, [axios, currentProject, hdfAPI]);

  const loadData = React.useCallback(async () => {
    if (hdfAPI) {
      axios.get(
        encodeURI("/label-hdf/v" + API_VERSION + "/" + currentProject),
        {params: {"hdf_dir": hdfAPI}}
      ).then(labelsResponse => {
        const newFileInfo = labelsResponse.data.file_info;
        const newLabels = labelsResponse.data.label_list;
        const maxLabelCount = Math.max(...newLabels.map(arr => arr.length));
        ReactDOM.unstable_batchedUpdates(() => {
          setFileInfo(newFileInfo);
          setLabels(newLabels);
          setNewLabels(Array(maxLabelCount).fill(""));
          setSelections(Array(newFileInfo.length).fill(false));
        });

        getColumns(currentFileIndex, newFileInfo, newLabels);
      }
      ).catch(error => handler(error));
    }
  }, [axios, currentProject, hdfAPI, getColumns, currentFileIndex]);

  React.useEffect(() => loadData(),
    [loadData],
  );

  const handleFileIndexChange = index => {
    setCurrentFileIndex(index);
    setHeadCells([]);
    getColumns(index, fileInfo, labels);
  };

  const rowCells = React.useCallback((row) =>
    [<TableCell key="0" padding="checkbox"/>].concat(
      columns.map((column, i) =>
        <TableCell key={i + 1} align="right">{row[`${column.label}-${column.column}`]}</TableCell>)
  ), [columns]);

  const optionTexts = fileInfo.map((info, i) =>
    `${info.file_name}${info.sheet ? " / Sheet " + info.sheet : ""} / ${info.cycle} (${labels[i].length})`);

  const toggleAllWithPredicate = predicate => {
    const allActive = fileInfo.every((info, i)=>
      !(predicate(i) && !selections[i]));
    const newSelections = fileInfo.map((info, i) =>
      predicate(i) ? !allActive : selections[i]);
    setSelections(newSelections);
  }

  const toggleAllWithSameLabelCount = () => {
    const currentLabelCount = labels[currentFileIndex].length;
    toggleAllWithPredicate(i => labels[i].length === currentLabelCount);
  }

  const toggleAllFromSameCycle = () => {
    const currentCycle = fileInfo[currentFileIndex].cycle;
    const currentLabelCount = labels[currentFileIndex].length;
    toggleAllWithPredicate(i => fileInfo[i].cycle === currentCycle
      && labels[i].length === currentLabelCount);
  }

  const toggleAllFromSameFile = () => {
    const currentFileName = fileInfo[currentFileIndex].file_name;
    const currentLabelCount = labels[currentFileIndex].length;
    toggleAllWithPredicate(i => fileInfo[i].file_name === currentFileName
      && labels[i].length === currentLabelCount);
  }

  const handleBatchSubmitLabels = async () => {
    const target_files = [];
    const new_labels = [];
    selections.forEach((val, i) => {
      if (val) {
        target_files.push(fileInfo[i]);
        new_labels.push(labels[i].map((label, j) =>
          !!newLabels[j] ? newLabels[j] : label.Label
        ))
      }
    });

    await axios.post(
      encodeURI("/modify-labels-hdf/v" + API_VERSION + "/" + currentProject),
      {
        directory: hdfAPI.split("/").slice(0,-1).join("/"),
        target_files,
        new_labels,
      }
    ).then(async _response => await loadData()
    ).catch(error => handler(error));
  }

  const handleSubmitLabels = async () => {
    const labelsToSubmit = labels[currentFileIndex].map((label, i) =>
      !!newLabels[i] ? newLabels[i] : label.Label
    );
    await axios.post(
      encodeURI("/modify-labels-hdf/v" + API_VERSION + "/" + currentProject),
      {
        directory: hdfAPI.split("/").slice(0,-1).join("/"),
        target_files: [fileInfo[currentFileIndex]],
        new_labels: [labelsToSubmit]
      }
    ).then(async _response => await loadData()
    ).catch(error => handler(error));
  }


  const subTableHead =
    <TableRow>
      {[<TableCell key="0" padding="checkbox"/>].concat(
        columns.map((column, i) =>
          <TableCell key={i + 1} align="right">
            <TextField
                type="text"
                placeholder="new label"
                value={newLabels[i]}
                onChange={event =>
                  setNewLabels([
                    ...newLabels.slice(0, i),
                    event.target.value,
                    ...newLabels.slice(i+1)
                  ])
                }
                style={{minWidth:"5rem", width:"100%"}}
                // label="データ提供者"
            />
          </TableCell>)
        )
      }
    </TableRow>;

  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(undefined);

  const labelCountForSelectedCycle = selections.every(sel => !sel) ?
    -1 : labels[selections.findIndex(sel => !!sel)].length;

  const currentCycleTogglable = labelCountForSelectedCycle < 0 ||
    labelCountForSelectedCycle === labels[currentFileIndex].length;

  return(
    <>
      <div style={{ textAlign: "justify", display: "flex",
        marginBottom: "1em"}}>
        <Button
          variant="contained"
          color="primary"
          disabled={!optionTexts?.length}
          onClick={event=>{
            setAnchorEl(event.currentTarget);
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {optionTexts[currentFileIndex] || "File / cycle"}
        </Button>
        <Popover
          id="simple-popover"
          open={dropdownOpen}
          anchorEl={anchorEl}
          onClose={()=>setDropdownOpen(false)}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <div>
            <List>
              <ListItem
                key={"toggleSameCycle"}
                role={undefined} dense button
                onClick={toggleAllFromSameCycle}
                disabled={!currentCycleTogglable}
              >
                <ListItemText primary={`Toggle all from same cycle (${fileInfo.length > 0 && fileInfo[currentFileIndex].cycle})`}/>
              </ListItem>
              <ListItem
                key={"toggleSameLabelCount"}
                role={undefined} dense button
                onClick={toggleAllWithSameLabelCount}
                disabled={!currentCycleTogglable}
              >
                <ListItemText primary={`Toggle all with same label count (${labels.length > 0 && labels[currentFileIndex].length})`}
                  style={{
                    whiteSpace: "nowrap",
                    marginRight:"1rem"
                }}
                />
              </ListItem>
              <ListItem
                key={"toggleSameFile"}
                role={undefined} dense button
                onClick={toggleAllFromSameFile}
                disabled={!currentCycleTogglable}
              >
                <ListItemText primary={"Toggle all from same file"}/>
              </ListItem>
              <ListItem
                key={"clearSelection"}
                role={undefined} dense button
                onClick={() => setSelections(selections.map(_ => false))}
              >
                <ListItemText primary="Clear selection"/>
              </ListItem>
              <Divider/>
              {optionTexts.map((text, i) =>
                <MenuItem
                  key={i}
                  selected={i===currentFileIndex}
                  role={undefined} dense button
                  onClick={()=>{
                    handleFileIndexChange(i);
                    setDropdownOpen(false);
                  }}
                >
                  <ListItemText primary={text}
                    style={{
                      whiteSpace: "nowrap",
                      marginRight:"1rem"
                  }}/>
                  <ListItemSecondaryAction>
                    <Checkbox
                      edge="end"
                      checked={selections[i]}
                      tabIndex={-1}
                      disableRipple
                      disabled={labelCountForSelectedCycle > 0 &&
                        labelCountForSelectedCycle !== labels[i].length}
                      // inputProps={{ 'aria-labelledby': labelId }}
                      onClick={()=>setSelections([
                        ...selections.slice(0, i),
                        !selections[i],
                        ...selections.slice(i+1)
                      ])}
                    />
                  </ListItemSecondaryAction>
                </MenuItem>
              )}
            </List>

          </div>
        </Popover>
        <Double style={{ marginLeft: "auto" }}>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              labels.length === 0 ||
                selections.every(sel => !sel) ||
                newLabels.findIndex(label => !!label) < 0 ||
                newLabels.findIndex(label => !!label) >=
                  labels[selections.findIndex(sel => !!sel)].length
            }
            onClick={handleBatchSubmitLabels}
            style={{ marginLeft: "1em" }}
          >
            Batch submit labels
          </Button>
          <Button
            variant="contained"
            color="secondary"
            disabled={
              labels.length === 0 ||
                newLabels.slice(0, labels[currentFileIndex].length)
                  .every(label => !label)
            }
            onClick={handleSubmitLabels}
            style={{ marginLeft: "1em" }}
          >
            Submit labels
          </Button>
        </Double>
      </div>
      {hdfAPI && headCells.length === 0 ?
        <Loading/> :
        <EnhancedTable
          headCells={headCells}
          subTableHead={subTableHead}
          toolbarLabel="HDF Table"
          rows={rows}
          rowKey={row => row.key}
          rowCells={rowCells}
          handleClick={()=>{}}
        />
      }
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={snackbarOpen}
        autoHideDuration={5000}
        onClose={() => setSnackbarOpen(false)}
        message={`行数（${totalRowCount}）が上限値を超えたので最初の${MAX_TABLE_ROWS}行のみ表示します。`}
        action={
          <>
            <IconButton size="small" aria-label="close" color="inherit"
              onClick={() => setSnackbarOpen(false)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </>
        }
      />
    </>
  );
}

export default HDFTable;
