import React from "react";
import { Assessment, Folder, InsertDriveFile, CreateNewFolder } from "@material-ui/icons";

const FileIcon = ({fileName, fileType, ...props}) => {
  const splits = fileName.split(".");
  const extension = splits.length >= 2 ?
    splits.slice(-1)[0] : "";

  if (fileType === "newFolder") {
    return <CreateNewFolder {...props}></CreateNewFolder>
  }

  return fileType === "folder" ?
    <Folder {...props}></Folder> :
    extension === 'h5' || (fileName !== 'file_list.csv' && extension === 'csv') ?
      <Assessment {...props}></Assessment> :
      <InsertDriveFile {...props}></InsertDriveFile>;
}

export default FileIcon;
