import React, { useState } from "react";
import { Backdrop, Button, Fade, IconButton, Modal, TextField, Tooltip } from "@material-ui/core";
import ChipInput from 'material-ui-chip-input'
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

function MemoModal(props) {
  const folderReaderClasses = props.useStyles();

  const [provider, setProvider] = useState();
  const [organization, setOrganization] = useState();
  const [date, setDate] = useState();
  const [sampleIdentifier, setSampleIdentifier] = useState();
  const [methodology, setMethodology] = useState();
  const [keywords, setKeywords] = useState();
  const [memo, setMemo] = useState("");

  const [nextConditionId, setNextConditionId] = useState(0);
  const [experimentalConditions, setExperimentalConditions] = useState([]);

  const [triedToSubmit, setTriedToSubmit] = useState(false);

  const [sampleIdTextFieldTouched, setSampleIdTextFieldTouched] = useState(false);

  const resetState = () => {
    setProvider();
    setOrganization();
    setDate();
    setSampleIdentifier();
    setMethodology();
    setKeywords();
    setMemo();
    setNextConditionId(0);
    setExperimentalConditions([]);
    setTriedToSubmit(false);
  }

  const addExperimentalCondition = () => {
    const id = nextConditionId;
    setNextConditionId(id + 1);
    setExperimentalConditions([
      ...experimentalConditions,
      {id}
    ])
  }

  const updateCondition = newCondition => {
    const id = newCondition.id;
    const index = experimentalConditions.findIndex(condition =>
      condition.id === id)
    setExperimentalConditions([
      ...experimentalConditions.slice(0, index),
      newCondition,
      ...experimentalConditions.slice(index + 1)
    ])
  }

  const deleteCondition = id => {
    const index = experimentalConditions.findIndex(condition =>
      condition.id === id)
    setExperimentalConditions([
      ...experimentalConditions.slice(0, index),
      ...experimentalConditions.slice(index + 1)
    ])
  }

  const readyToSubmit = () => providerValid() && organizationValid() &&
    methodologyValid() && experimentalConditionsValid();

  const providerValid = () => !!provider?.length;
  const organizationValid = () => !!organization?.length;
  const methodologyValid = () => !!methodology?.length;
  const experimentalConditionsValid = () =>
    experimentalConditions.every(condition => !!condition.name && !!condition.value);

  const submitMetadata = () => {
    if (readyToSubmit()) {
      const conditions = {};
      experimentalConditions.forEach(condition =>
        conditions[condition.name] = condition.value)
      const metadata = {
        provider,
        organization,
        date: date || new Date().toISOString().substring(0, 10),
        methodology,
        sampleIdentifier,
        keywords,
        experimentalConditions: conditions,
        memo,
      }
      props.postData(
        JSON.stringify(metadata)
      )
      resetState();
      props.modalClose();
    } else {
      setTriedToSubmit(true);
    }
  }

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={folderReaderClasses.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div
            className={folderReaderClasses.paper}
            style={{
              width: "40rem",
              overflowY: "auto",
              maxHeight: "95vh",
            }}
          >
            <h2 id="modal-title" style={{marginBottom:0}}>Metadata entry</h2>
            <div
              style={{display:'flex'}}
            >
              <TextField
                type="text"
                label="データ提供者"
                onChange={(event) => {
                  setProvider(event.target.value);
                }}
                error={(triedToSubmit && !providerValid())}
                helperText={(triedToSubmit && !providerValid()) ? "データ提供者は必須です" : ""}
                style={{
                  width: "16rem",
                  marginRight: "2rem"
                }}
              ></TextField>
              <TextField
                type="text"
                label="所属"
                onChange={(event) => {
                  setOrganization(event.target.value);
                }}
                error={(triedToSubmit && !organizationValid())}
                helperText={(triedToSubmit && !organizationValid()) ? "所属は必須です" : ""}
                style={{
                  width: "16rem",
                }}
              ></TextField>
            </div>
            <div
              style={{display:'flex'}}
            >
              <TextField
                id="date"
                label="実験方法"
                placeholder="例：CV、SEV"
                type="text"
                onChange={(event) => {
                  setMethodology(event.target.value);
                }}
                error={(triedToSubmit && !methodologyValid())}
                helperText={(triedToSubmit && !methodologyValid()) ? "実験方法は必須です" : ""}
                style={{
                  width: "16rem",
                  marginRight: "2rem"
                }}
              />
              <TextField
                id="date"
                label="データ取得日"
                type="date"
                onChange={(event) => {
                  setDate(event.target.value);
                }}
                defaultValue={new Date().toISOString().substring(0, 10)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </div>
            <div>
              <TextField
                id="date"
                label="サンプル識別子（任意）"
                placeholder="サンプル名、試料名など（研究室での呼称）"
                helperText={sampleIdTextFieldTouched && "同じサンプルに対する様々な計測データの関連付けに利用"}
                onFocus={() => {setSampleIdTextFieldTouched(true)}}
                onBlur={() => {setSampleIdTextFieldTouched(false)}}
                type="text"
                onChange={(event) => {
                  setSampleIdentifier(event.target.value);
                }}
                fullWidth
              />
            </div>
            <div>
              <ChipInput
                label="キーワード・タグ（任意）"
                newChipKeys={['Enter', 'Space']}
                newChipKeyCodes={[13, 32, 188]}
                placeholder={!keywords?.length ? "例：白金触媒、論文に使ったデータ、うまくいった例" : null}
                onChange={(chips) => {setKeywords(chips)}}
                fullWidth
              />
            </div>
            <div>
              <h4 style={{marginBottom:0}}>実験状況（任意）</h4>
              {experimentalConditions.map(condition =>
                <div
                  style={{
                    display:'flex',
                    alignItems: 'center',
                  }}
                  key={condition.id}
                >
                  <TextField
                    type="text"
                    label="項目名"
                    onChange={(event) => {
                      updateCondition({
                        ...condition,
                        "name": event.target.value
                      })
                    }}
                    error={(triedToSubmit && !condition.name)}
                    helperText={(triedToSubmit && !condition.name) ? "項目名は必須です" : ""}
                    placeholder="例：温度"
                    style={{
                      width: "11rem",
                      marginRight: "1rem"
                    }}
                  ></TextField>
                  <TextField
                    type="text"
                    label="値"
                    onChange={(event) => {
                      updateCondition({
                        ...condition,
                        "value": event.target.value
                      })
                    }}
                    error={(triedToSubmit && !condition.value)}
                    helperText={(triedToSubmit && !condition.value) ? "値は必須です" : ""}
                    style={{
                      width: "21rem",
                    }}
                  ></TextField>
                  <div>
                    <Tooltip title="項目を消去" aria-label="項目を消去">
                      <IconButton
                        aria-label="delete"
                        size="medium"
                        onClick={() => deleteCondition(condition.id)}
                      >
                        <DeleteIcon fontSize="medium" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              )}
            </div>
            <div>
              <Button
                color="default"
                // className={classes.button}
                style={{ backgroundColor: 'transparent' }}
                startIcon={<AddIcon />}
                onClick={addExperimentalCondition}
              >
                項目を追加
              </Button>
            </div>
            <br/>
            <TextField
              multiline
              fullWidth
              label="備考"
              rows={10}
              type="text"
              variant="outlined"
              // placeholder={place_text}
              onChange={(event) => {
                setMemo(event.target.value);
              }}
            ></TextField>
            <div className={folderReaderClasses.buttonRoot}>
              <div className={folderReaderClasses.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={submitMetadata}
                >
                  OK
                </Button>
              </div>
              <div className={folderReaderClasses.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    props.modalClose();
                    resetState();
                  }}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default MemoModal;
