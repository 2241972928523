import { makeStyles } from "@material-ui/core";
import React from "react"
import { useDrag, useDrop } from "react-dnd";
import FileIcon from "./FileIcon";

const useStyles = makeStyles((theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    position: 'relative',
    alignItems: 'center',
    width: '8rem',
    height: '8rem',
    fontSize: '50%',
    transform: 'translateY(-2.6rem)',
    "&:hover": {
      backgroundColor: 'rgba(0, 255, 0, 0.1)',
      cursor: 'pointer',
    }
  },
  fileNameSpan: {
    width: '7.5rem',
    wordBreak: 'break-all',
    transform: 'translateY(2.6rem)',
  },
  icon: {
    transform: 'translateY(2.6rem)',
  }
}));

const style = {
  backgroundColor: "rgba(0, 255, 0, 0.5)",
};

const IconViewItem = ({fileName, fileType, address, isSelected,
  onClick, onDoubleClick, onMove}) => {

  const {card, fileNameSpan, icon} = useStyles();

  const [, drag] = useDrag(() => ({
    type: "iconViewItem",
    item: {fileName, address},
    options: {dropEffect: "move"},
  }), [fileName, address])

  const [, drop] = useDrop(() => ({
    accept: "iconViewItem",
    canDrop: (item) => {
      return fileType === 'folder' &&
        !(item.address === address && item.fileName === fileName);
    },
    drop: (item) => onMove(item.fileName, item.address, address + fileName + "/"),
  }), [fileName, address])

  function attachRef(el) {
    drag(el);
    drop(el);
  }

  return (
    <div
      ref={attachRef}
      key={fileName}
      className={card}
      style={isSelected ? style : {}}
      onDoubleClick={onDoubleClick}
      onClick={onClick}
    >
      <FileIcon
        fileName={fileName}
        fileType={fileType}
        className={icon}
        fontSize="large"
      />
      <span className={fileNameSpan}>
        {fileName}
      </span>
    </div>
  );
}

export default IconViewItem;
