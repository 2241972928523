import { makeStyles } from "@material-ui/core";
import React from "react";
import { useDrop } from "react-dnd";
import DetailView from "./DetailView";
import FinderItem from "./FinderItem";

const useStyles = makeStyles(() => ({
  finderView: {
    display: 'flex',
    width: 'calc(90vw - 240px)',
    minWidth: '24rem',
    height: '70vh',
    overflowX: 'auto',
    overflowY: 'hidden',
    outline: '2px solid #999',
    marginTop: '2px',
  },
  folderViewContainer: {
    borderRight: '1px solid #999',
    minWidth: '12rem',
    height: '100%',
    overflow: 'auto',
    flexShrink: 0,
    // '&:first-child': {
    //   borderLeft: '1px solid #999',
    // }
  },
  folderView: {
    maxHeight: '100%',
    textAlign: 'left',
    wordBreak: 'break-all',
  },
  fixedWidth: {
    width: '12rem',
  },
  detailViewContainer: {
    paddingTop: '3rem',
    borderRight: '1px solid #999',
    width: '12rem',
    flexShrink: 0,
    height: '100%',
    wordBreak: 'break-all',
  },
}));

function FolderView({directory, selectedObject, isSelected, currentProject,
    onDragOn, onDrop, onClickFile, onClickFolder, address,
    nextAddress, onMove, onCreateFolder, }) {

  const { folderView, folderViewContainer, fixedWidth } = useStyles();

  const [, drop] = useDrop(() => ({
    accept: "finderItem",
    canDrop: (item) => {
      return item.address !== address &&
        address.indexOf(item.address + item.fileName + "/") !== 0;
    },
    drop: (item) => onMove(item.fileName, item.address, address),
  }), [address])

  let highlighted = null;
  if (nextAddress) {
    const splits = nextAddress.split("/");
    highlighted = splits[splits.length - 2];
  }

  const icons = directory.map(file =>
    <FinderItem
      key={address + file.name}
      isSelected={isSelected && (selectedObject === file)}
      isHighlighted={file.name === highlighted}
      fileName={file.name}
      fileType={file.type}
      address={address}
      onClick={() => file.type === "folder" ?
        onClickFolder(address, file) : onClickFile(address, file)}
    />
  );

  const folderViewClasses = icons.length === 0 ?
    `${folderView} ${fixedWidth}` : folderView;

  return(
    <div
      className={folderViewContainer}
      onDragOver={onDragOn}
      onDrop={event => onDrop(event, address)}
      ref={drop}
    >
      <div
        className={folderViewClasses}
      >
        {icons}
        <FinderItem
          isSelected={false}
          isHighlighted={false}
          fileName="新規フォルダを作成..."
          fileType={"newFolder"}
          address={address}
          onClick={() => onCreateFolder(address)}
        />
      </div>
    </div>
  )
}

const FinderView = React.forwardRef((props, ref) => {
  const { finderView, detailViewContainer } = useStyles();

  return (
    <div
      className = {finderView}
      ref={ref}
    >
      {[...Array(props.directories.length).keys()].map(i =>
        <FolderView
          directory={props.directories[i]}
          address={props.addresses[i]}
          nextAddress={props.addresses[i+1]}
          key={i}
          {...props}
        />
      )}
      {props.isSelected && props.selectedObject.type !== "folder" ?
        <div
          className={detailViewContainer}
        >
          <DetailView
            file={props.selectedObject}
          />
        </div> :
        null
      }
    </div>
  );
});

export default FinderView;
