import { makeStyles } from "@material-ui/core";
import React from "react";
import { formatBytes } from "../../utils";
import FileIcon from "./FileIcon";

const formatDate = date => {
  const options = { year: '2-digit', month: '2-digit', day: '2-digit', 
    hour: '2-digit', minute: '2-digit' };
  return date.toLocaleString(undefined, options);
}

const useStyles = makeStyles(() => ({
  detailView: {
  },
  detailArea: {
    textAlign: "left",
    paddingTop: "1rem",
    paddingLeft: "2rem",
    paddingRight: "2rem",
  },
  itemName: {
    fontWeight: "bold"
  }
}));

const DetailView = ({file}) => {
  const {name, type, mtime, size} = file;

  const { detailView, itemName, detailArea } = useStyles();
  
  const sizeNum = parseInt(size);
  const sizeString = sizeNum > 0 ? 
    formatBytes(sizeNum) : "-";
  
  const date = new Date(parseFloat(mtime) * 1000);

  return (
    <div
      className={detailView}
    >
      <h3>{name}</h3>
      <FileIcon
        fileName={name}
        fileType={type}
        style={{
          fontSize:"7rem",
        }}
      />
      <div className={detailArea}>
        <div>
          <div><span className={itemName}>Type:</span> {type}</div>
        </div>
        {/* <div className={itemName}>Size:</div><div>{sizeString}</div> */}
        <div>
          <div><span className={itemName}>Size:</span> {sizeString}</div>
        </div>
        <div>
          <div className={itemName}>Last modified:</div><div>{formatDate(date)}</div>
        </div>
      </div>
    </div>
  )
}

export default DetailView;
