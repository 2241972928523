import React from "react";
import List from "@material-ui/core/List";

import Divider from "@material-ui/core/Divider";

import CallMadeIcon from "@material-ui/icons/CallMade";
import BlurOnIcon from "@material-ui/icons/BlurOn";
import AppsIcon from "@material-ui/icons/Apps";
import StorageIcon from "@material-ui/icons/Storage";
import BarChartIcon from "@material-ui/icons/BarChart";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Avatar from '@material-ui/core/Avatar';
import Folder from "@material-ui/icons/Folder";
import Star from "@material-ui/icons/Star";
import HelpOutline from "@material-ui/icons/HelpOutline";

const MenuContent = ({classes, handleCurrentPageChange,
  drawerOpen, setDrawerOpen}) => {
  const [LRNestedOpen, setLRNestedOpen] = React.useState(false);

  const handleLRNestedOpenChange = React.useCallback(() => {
    if (drawerOpen) {
      setLRNestedOpen(!LRNestedOpen);
    } else {
      setLRNestedOpen(true);
      setDrawerOpen(true);
    }
  }, [LRNestedOpen, drawerOpen, setDrawerOpen])

  const displayLRNestedOpen = LRNestedOpen && drawerOpen;

  return (
    <List>
      <div
        onClick={() => handleCurrentPageChange("Import")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <Folder />
          </ListItemIcon>
          <ListItemText primary="Drive" />
        </ListItem>
      </div>
      <Divider />
      <div className={classes.link}>
        <ListItem>
          <ListItemIcon>
            <Star />
          </ListItemIcon>
          <ListItemText primary="HDF Analysis" />
        </ListItem>
      </div>
      {process.env.REACT_APP_API_VERSION >= 2
       || process.env.NODE_ENV === 'development' ?
        <div
          className={classes.link}
          onClick={() => handleCurrentPageChange("HDFTable")}
        >
          <ListItem button>
            <ListItemIcon>
              <BlurOnIcon />
            </ListItemIcon>
            <ListItemText primary="Table" />
          </ListItem>
        </div> :
        null
      }
      <div
        className={classes.link}
        onClick={() => handleCurrentPageChange("ScatterHDF")}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="Scatter" />
        </ListItem>
      </div>
      <div
        className={classes.link}
        onClick={() => handleCurrentPageChange("CV")}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="CV" />
        </ListItem>
      </div>
      <div
        className={classes.link}
        onClick={() => handleCurrentPageChange("LSV")}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="LSV" />
        </ListItem>
      </div>
      <div
        className={classes.link}
        onClick={() => handleCurrentPageChange("BGSLSV")}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="BGS LSV" />
        </ListItem>
      </div>
      <div
        className={classes.link}
        onClick={() => handleCurrentPageChange("Tafel")}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="Tafel" />
        </ListItem>
      </div>
      <Divider />
      <div className={classes.link}>
        <ListItem>
          <ListItemIcon>
            <Star />
          </ListItemIcon>
          <ListItemText primary="CSV Analysis" />
        </ListItem>
      </div>
      <div onClick={()=>handleCurrentPageChange("DataViewer")} className={classes.link}>
        <ListItem button>
          <ListItemIcon>
            <StorageIcon />
          </ListItemIcon>
          <ListItemText primary="Data Viewer" />
        </ListItem>
      </div>
      <div
        onClick={() => handleCurrentPageChange("Correlation")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <BlurOnIcon />
          </ListItemIcon>
          <ListItemText primary="Scatter" />
        </ListItem>
      </div>
      <div
        onClick={() => handleCurrentPageChange("Histogram")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <BarChartIcon />
          </ListItemIcon>
          <ListItemText primary="Histogram" />
        </ListItem>
      </div>
      <div
        onClick={() => handleCurrentPageChange("HeatMap")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <AppsIcon />
          </ListItemIcon>
          <ListItemText primary="Heatmap of Corr." />
        </ListItem>
      </div>
      <div
        onClick={handleLRNestedOpenChange}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <CallMadeIcon />
          </ListItemIcon>
          <ListItemText secondary="Linear Regressions" />
          {displayLRNestedOpen  ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={displayLRNestedOpen } timeout="auto" unmountOnExit>
          <List
            component="div"
            disablePadding
            onClick={() =>
              handleCurrentPageChange("LinearRegression")
            }
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Avatar sizes="small">S</Avatar>
              </ListItemIcon>
              <ListItemText primary="Standard" />
            </ListItem>
          </List>
          <List
            component="div"
            disablePadding
            onClick={() => handleCurrentPageChange("Lasso")}
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Avatar sizes="small">L</Avatar>
              </ListItemIcon>
              <ListItemText primary="Lasso" />
            </ListItem>
          </List>
          <List
            component="div"
            disablePadding
            onClick={() => handleCurrentPageChange("Ridge")}
          >
            <ListItem button className={classes.nested}>
              <ListItemIcon>
                <Avatar sizes="small">R</Avatar>
              </ListItemIcon>
              <ListItemText primary="Ridge" />
            </ListItem>
          </List>
        </Collapse>
      </div>
      <Divider />
      <div
        onClick={() => handleCurrentPageChange("Manual")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <HelpOutline />
          </ListItemIcon>
          <ListItemText primary="Manual" />
        </ListItem>
      </div>
      <div
        onClick={() => (document.location.href = "/logout")}
        className={classes.link}
      >
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItem>
      </div>
    </List>
  );
}

export default MenuContent;
