import { CircularProgress, makeStyles } from "@material-ui/core";
import React from "react";

const useStyles = makeStyles({
  spinnerContainer: {
    height: '65vh',
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center',
  }
});

const Loading = () => {
  const {spinnerContainer} = useStyles();

  return (
    <div className={spinnerContainer}>
      <div>
        <CircularProgress />
      </div>
      <h2>Loading...</h2>
    </div>
  );
}

export default Loading;
