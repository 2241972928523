import XLSX from "xlsx"
import { handler } from "../utils";

const API_VERSION = process.env.REACT_APP_API_VERSION;
const SKIP_EXTENSIONS = ['doc', 'pdf', 'pptx', 'md'];

const getSheetCount = async file => {
  const workbook = await new Promise((resolve) => {
      let fileReader = new FileReader();
      fileReader.onload = function(e) {
        const data = new Uint8Array(e.target.result);
        resolve(XLSX.read(data, {type: 'array'}));
      };
      fileReader.readAsArrayBuffer(file);
  });

  return workbook.SheetNames.length;
}

const fileListCsvRows = async files => {
  const rows = [];
  await Promise.all(files.map(async file => {
    const file_name = removeExtension(file.file.name);
    const expand_type = "P";
    if (file.file.name.split(".").slice(-1)[0].toLowerCase() === "xlsx") {
      const sheetCount = await getSheetCount(file.file);
      rows.push(...[...Array(sheetCount).keys()].map(sheet => ({
        file_name,
        sheet,
        expand_type,
      })));
    } else {
      rows.push({
        file_name
      });
    }
  }));
  return rows;
}

const generateFileList = async (entry, root_raw) => {
  if (entry.isFile) {
    console.log("File Uploading");
    const file = await new Promise((resolve) => {
      entry.file((file) => {
        resolve(file);
      });
    });
    const shouldSkipHdf =
      SKIP_EXTENSIONS.indexOf(file.name.split(".").slice(-1)[0].toLowerCase()) >= 0;

    const fileList = [{file, path: shouldSkipHdf ? "/" : root_raw}];
    const rows =  shouldSkipHdf ?
      null : await fileListCsvRows(fileList);
    return [fileList, rows]
  } else {
    console.log("Folder Uploading");
    const fileList = await traverseFileTree(entry, root_raw);

    // file_list.csvを読み込む、
    const index = fileList.findIndex(f => f.file.name === "file_list.csv");
    if (index >= 0) {
      const newFileList = [...fileList.slice(0, index), ...fileList.slice(index+1)];
      return [
        newFileList,
        await readFileListCsv(fileList[index].file)
      ];
    } else {
      const filteredFileList = fileList.filter(file =>
        SKIP_EXTENSIONS.indexOf(file.file.name.split(".").slice(-1)[0]) < 0);

      const rows = filteredFileList.length > 0 ?
        await fileListCsvRows(filteredFileList) :
        null;
      if (!rows) {
        fileList.forEach(file => file.path = file.path.slice(root_raw.length))
      }
      return [fileList, rows];
    }
  }
};

const traverseFileTree = async (entry, path) => {
  if (entry.isFile) {
    const file = await new Promise((resolve) => {
      entry.file((file) => {
        resolve(file);
      })
    })
    return [{ file: file, path: file.name==="file_list.csv" ? "/" : path }];
  } else if (entry.isDirectory) {
    const directoryReader = entry.createReader();
    const entries = await new Promise((resolve) => {
      directoryReader.readEntries((entries) => {
        resolve(entries);
      });
    });
    // 空のフォルダをアップロードするとバグる(未修整)
    return [].concat(...await Promise.all(entries.map(e =>
      traverseFileTree(e, path + entry.name + "/"))));
  }
};

const readFileListCsv = async file => {
  const fileListCsvText = await new Promise((resolve) => {
    const reader = new FileReader();
    reader.onload = function() {
      resolve(reader.result);
    }
    reader.readAsText(file);
  });
  const lines = fileListCsvText.split('\n').map(line => line.replace('\r', ''));
  const headers = lines[0].split(',');
  const rows = lines.slice(1).filter(line => line.length > 0).map(line => {
    const row = {};
    const cells = line.split(',');
    for (let i = 0; i < cells.length; i++) {
      row[headers[i]] = cells[i];
    }
    return row;
  })
  return rows;
}

const removeExtension = filename => {
  console.log(filename);
  const splits = filename.split('.');
  return splits.length === 1 ? filename :
    splits.slice(0, -1).join('.');
}

const generateFileListCsvBlob = (fileListEntries, root_gen) => {
  const columns = ["file_name"].concat(
    Object.keys(fileListEntries[0]).filter(key =>
      key !== "file_name" && key !== "id")
  );
  const fileListText = [columns.join(",")].concat(
    fileListEntries.map(entry =>
      columns.map(column => {
        const type = typeof entry[column];
        return type === 'string' || type === 'number' ?
          entry[column] : "";
      }).join(",")
    )).join("\n");
  const blob = new Blob([fileListText], {
    type: 'text/plain',
    name: 'file_list.csv',
  });
  blob.path = "/" + root_gen;
  return blob;
}

const uploadOnly = async(ax, currentProject, uploadFiles, address) => {
  const promises = [];

  for (const file of uploadFiles) {
    const data = new FormData();
    data.append("file", file.file)
    console.log("path", file.path);
    promises.push(
      ax.post(
        encodeURI("/upload-file/v" + API_VERSION + "/" + currentProject),
        data,
        {params: {root: address + file.path}}
      ).catch(error => handler(error))
    );
  }
  await Promise.all(promises);
}

const uploadAndGenerateHdf = async (ax, currentProject, root,
  metadata, uploadFiles, fileListEntries) => {

  const root_gen = root + "/generated/";
  const fileListCsvBlob = generateFileListCsvBlob(fileListEntries, root_gen);

  const promises = [];

  // file_list.csvをアップロード
  const data = new FormData();
  data.append("file", fileListCsvBlob, "file_list.csv");
  promises.push(
    ax.post(
      encodeURI("/upload-file/v" + API_VERSION + "/" + currentProject),
      data,
      {params: {root: root_gen}}
    )
  );

  for (const file of uploadFiles) {
    const data = new FormData();
    data.append("file", file.file)
    promises.push(
      ax.post(
        encodeURI("/upload-file/v" + API_VERSION + "/" + currentProject),
        data,
        {params: {root: file.path}}
      )
    );
  }
  console.log("file uploading");
  await Promise.all(promises);
  console.log("complete");

  console.log("create hdf file");
  const hdfResponse = await ax.post(
    encodeURI("/generate-hdf/v" + API_VERSION + "/" + currentProject),
    null,
    {
      params: {
        "dir" : root
      }
    }
  )
  console.log("complete");
  console.log("upload metadata");
  const metadataResponse = await ax.post(
    encodeURI("/upload-metadata/v" + API_VERSION + "/" + currentProject),
    {
      metadata,
      directory: root_gen
    }
  )
  console.log(hdfResponse.data.hdf_path)
  return [hdfResponse, metadataResponse];
}

export { generateFileList, uploadOnly, uploadAndGenerateHdf };
