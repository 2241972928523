import React, { Component } from "react";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import ConfirmDialog from "../templates/ConfirmDialog";
import IconView from "./IconView";
import { FormControlLabel, Switch } from "@material-ui/core";
import FinderView from "./FinderView";
import { handler } from "../../utils";
import TextEntryDialog from "../templates/TextEntryDialog";
import { Double } from "csv-viewer-react"

const API_VERSION = process.env.REACT_APP_API_VERSION
const ENABLE_FINDER_MODE = (process.env.REACT_APP_API_VERSION > 1 ||
  process.env.NODE_ENV === 'development');

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    display: 'flex',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

// const ProjectSelect = (props) => {

//   if (props.projects.length == 1) {
//     const project = props.projects[0];
//     this.setState({
//       currentProjectID: project.project_id,
//       currentProjectName: project.project_name,
//     });
//     return project.project_name;
//   }
//   return (
//     <Select
//       id="project-select"
//       value={this.state.currentProjectID ?? props.projects[0].project_id}
//       onChange={(event) => {
//         let currentProjectName = "";
//         props.projects.map((project) => {
//           if (project.project_id == event.target.value)
//             currentProjectName = project.project_name;
//         });
//         this.setState({
//           currentProjectID: event.target.value,
//           currentProjectName: currentProjectName,
//         });
//       }}
//       style={{ marginLeft: "10px" }}
//     >
//       {props.projects.map((project) => (
//         <MenuItem value={project.project_id} key={project.project_id}>
//           {project.project_name}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// };
class FolderReader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addresses: ["/"],
      directories: [[]],
      nextDirectories: [],
      isSelected: false,
      selectedObject: {name: "", type: ""},
      confirmOpen: false,
      file_data: null,
      userID: props.userID,
      projects: props.projects,
      currentProject: props.currentProject,
      text: "",
      method: null,
      columnMode: false,
      finderMode: ENABLE_FINDER_MODE,
      shouldScrollRight: false,
      directoryNameDialogOpen: false,
      folderCreationAddress: "",
    };
    this.finderViewRef = React.createRef();
    this.ax = props.axios;
  }

  componentDidMount = async () => {
    await this.changeDirectory("/");
  }

  componentDidUpdate = () => {
    if (this.state.shouldScrollRight) {
      if (this.finderViewRef.current) {
        this.finderViewRef.current.scrollLeft =
          this.finderViewRef.current.scrollWidth;
      }
      this.setState({
        shouldScrollRight: false
      });
    }
  }

  handleMove = async (fileName, fromAddress, toAddress) => {
    console.log("moving", fileName, "from", fromAddress, "to", toAddress)
    await this.ax.post(
      encodeURI("/move-file/v" + API_VERSION + "/" + this.state.currentProject),
      {
        file: fromAddress + fileName,
        destination: toAddress,
      }
    ).catch(error => handler(error));
    await this.refreshDirectories();
  }

  handleCreateDirectory = async directoryName => {
    console.log("create directory name", directoryName);
    await this.ax.post(
      encodeURI("/create-folder/v" + API_VERSION + "/" + this.state.currentProject),
      {
        location: this.state.folderCreationAddress,
        name: directoryName,
      }
    ).catch(error => handler(error));
    await this.refreshDirectories();
    this.setState({
      folderCreationAddress: "",
      directoryNameDialogOpen: false,
    })
  }

  handleToggleColumnMode = () => {
    this.setState({
      columnMode: !this.state.columnMode,
    })
  }

  handleOpenFileOrDirectory = async (address, file) => {
    if(file.type === "folder"){
      this.setState({
        nextDirectories: [],
      })
      await this.changeDirectory(address + file.name
        + "/");
    } else if(this.props.isAnalyzable(file)){
      this.setState({
        confirmOpen: true,
        selectedObject: file,
        selectedObjectDirectory: address,
        method: this.analyze,
        text: "を分析用データとしてロードしますか？"
    })}
  }

  handleSelectFileOrDirectory = async (address, file) => {
    if(file.type !== "folder"){
      const index = this.state.addresses.indexOf(address);
      this.setState({
        directories: this.state.directories.slice(0, index + 1),
        addresses: this.state.addresses.slice(0, index + 1),
        nextDirectories: [],
      })
    }
    this.setState({
      isSelected: true,
      selectedObject: file,
      selectedObjectDirectory: address,
    });
  }

  handleToggleFinderMode = () => {
    this.setState({
      finderMode: !this.state.finderMode,
    });
  }

  DragOn = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "copy";
  };
  jumpNext = async () => {
    const destination = this.state.nextDirectories[this.state.nextDirectories.length - 1];
    this.setState({
      nextDirectories: this.state.nextDirectories.slice(0, -1)
    });
    await this.changeDirectory(destination);
  };
  jumpPrev = async () => {
    if (this.state.finderMode && this.state.isSelected && this.state.selectedObject.type !== 'folder') {
      this.setState({
        isSelected: false,
        selectedObject: {name: "", type: ""},
      });
    } else {
      this.setState({
        isSelected: false,
        selectedObject: {name: "", type: ""},
        nextDirectories: this.state.nextDirectories.concat(
          [this.state.addresses.slice(-1)[0]]),
      });
      await this.changeDirectory(this.state.addresses.slice(-2)[0]);
    }
  };
  changeDirectory = async (next) => {
    const parentIndex = this.state.addresses.indexOf(next);
    const sliceIndex = parentIndex >= 0 ? parentIndex :
      this.state.addresses.filter(address =>
        next.startsWith(address)).length;
    await this.ax
    .post(
      encodeURI("/directory/v" + API_VERSION + "/" + this.state.currentProject),
      {
        "directory": next
      }
    ).then((res) => {
      this.setState({
        directories: [...this.state.directories.slice(0, sliceIndex),
          res.data.files],
        addresses: [...this.state.addresses.slice(0, sliceIndex),
          next],
        shouldScrollRight: true,
      });
      if (!this.state.finderMode) {
        this.setState({
          isSelected: false,
          selectedObject: {name: "", type: ""},
        });
      }
    }).catch(error => handler(error));
  }
  refreshDirectories = async (addresses = this.state.addresses) => {
    try {
      const directories = [...await Promise.all(addresses.map(address => this.ax.post(
        encodeURI("/directory/v" + API_VERSION + "/" + this.state.currentProject),
        {
          "directory": address
        }
      )))].map(res => res.data.files);
      this.setState({
        directories,
        shouldScrollRight: true,
      });
    } catch (error) {
      handler(error);
    }
  }
  download = async () => {
    const params = {
      directory: this.state.selectedObjectDirectory,
      file_name: this.state.selectedObject.name,
      file_type: this.state.selectedObject.type,
    }
    console.log("download request : ", params);
    await this.ax.get(
      encodeURI("/download-file/v" + API_VERSION + "/" + this.state.currentProject),
      {params, responseType: "arraybuffer"}
    ).then(dl_response => {
      console.log("download response: ", dl_response);
      //BOMを付与する（Excelでの文字化け対策）
      const bom = new Uint8Array(
        params.file_type === "csv" || params.file_type === "txt"
          ? [0xef, 0xbb, 0xbf]
          : []);
      //Blobでデータを作成する
      const blob = new Blob([bom, dl_response.data],
        {type: "application/octet-stream"});
      //BlobからオブジェクトURLを作成する
      const url = (window.URL || window.webkitURL).createObjectURL(blob);
      //ダウンロード用にリンクを作成する
      const downloadLink = document.createElement("a");
      //リンク先に上記で生成したURLを指定する
      downloadLink.href = url;
      //download属性にファイル名を指定する
      downloadLink.download =
        this.state.selectedObject.type!=="folder"
          ? this.state.selectedObject.name
          : this.state.selectedObject.name + ".zip";
      //作成したリンクをクリックしてダウンロードを実行する
      downloadLink.click();
      //createObjectURLで作成したオブジェクトURLを開放する
      (window.URL || window.webkitURL).revokeObjectURL(url);
    }).catch(error => handler(error));
    if (this.state.selectedObject.type === "folder") {
      console.log("delete zip file")
      await this.ax.post(
        encodeURI("/delete-file/v" + API_VERSION + "/" + this.state.currentProject),
        {
          directory: this.state.selectedObjectDirectory + "/" + this.state.selectedObject.name + ".zip",
        }
      ).catch(error => handler(error));
      console.log("complete")
    }
    await this.refreshDirectories();
    this.setState({
      isSelected: false,
      selectedObject: {name: "", type: ""},
    })
  }
  delete = async () => {
    const del = {directory: this.state.selectedObjectDirectory + this.state.selectedObject.name}
    console.log("deleting item ", del.directory);
    const firstIndexToDelete = this.state.addresses.findIndex(dirName =>
      dirName.indexOf(del.directory) >= 0);
    const addresses = firstIndexToDelete >= 0 ?
       this.state.addresses.slice(0, firstIndexToDelete) :
       this.state.addresses;
    await this.ax.post(encodeURI("/delete-file/v" + API_VERSION + "/" + this.state.currentProject),
      del).catch(error => handler(error));
    await this.refreshDirectories(addresses);
    this.setState({
      addresses,
      confirmOpen: false,
      isSelected: false,
      selectedObject: {name: "", type: ""},
    })
  }

  analyze = async () => {
    await this.props.analyzeFile(this.state.selectedObjectDirectory,
      this.state.selectedObject.name, this.state.selectedObject.type)
    await this.refreshDirectories();
    this.setState({
      confirmOpen: false,
      isSelected: false,
      selectedObject: {name: "", type: ""},
    })
  }

  render() {
    const rootAd = "/";
    const isPrev = this.state.addresses.slice(-1)[0] !== rootAd;
    const isNext = this.state.nextDirectories.length > 0;

    const finderModeToggle = ENABLE_FINDER_MODE ?
      <FormControlLabel
        control={<Switch
          checked={this.state.finderMode}
          onChange={this.handleToggleFinderMode}
        />}
        label="Column mode"
      />
      : null;

    const iconView = this.state.finderMode ?
      <FinderView
        ref={this.finderViewRef}
        directories={this.state.directories}
        addresses={this.state.addresses}
        selectedObject={this.state.selectedObject}
        isSelected={this.state.isSelected}
        currentProject={this.state.currentProject ??
          this.props.projects[0].project_name}
        onDragOn={this.DragOn}
        onDrop={this.props.handleDrop}
        onClickFolder={(address, file) => {
          this.handleOpenFileOrDirectory(address, file);
          this.handleSelectFileOrDirectory(address, file);
        }}
        onClickFile={(address, file) => {
          this.handleSelectFileOrDirectory(address, file);
          this.setState({
            shouldScrollRight: true
          });
        }}
        onCreateFolder={address => {
          this.setState({
            folderCreationAddress: address,
            directoryNameDialogOpen: true,
          })
        }}
        onMove={this.handleMove}
      /> :
      <IconView
        directory={this.state.directories.slice(-1)[0]}
        address={this.state.addresses.slice(-1)[0]}
        selectedObject={this.state.selectedObject}
        isSelected={this.state.isSelected}
        currentProject={this.state.currentProject ??
          this.props.projects[0].project_name}
        onDragOn={this.DragOn}
        onDrop={event => this.props.handleDrop(event, this.state.addresses.slice(-1)[0])}
        onDoubleClick={this.handleOpenFileOrDirectory}
        onClick={this.handleSelectFileOrDirectory}
        onMove={this.handleMove}
        onCreateFolder={address => {
          this.setState({
            folderCreationAddress: address,
            directoryNameDialogOpen: true,
          })
        }}
      />;

    return (
      <div>
        <Typography component="div">
          <Box
            id="currentDirectoryBox"
            fontWeight="fontWeightBold"
            fontSize="h6.fontSize"
            textAlign="left"
            minHeight="60px"
            m={0}
          >
            Current Directory :{" "}
            {/* TODO: 現状複数のプロジェクトにまたがることはないが、今後複数プロジェクトにまたがるときは選択式にする*/}
            {/* <ProjectSelect projects={this.props.projects}></ProjectSelect> */}
            {this.state.currentProject}
            {this.state.addresses.slice(-1)[0]}
          </Box>
        </Typography>
        <div style={{ textAlign: "justify", display: "flex" }}>
          <div style={{ textAlign: "justify", display: "flex" }}>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              disabled={!isPrev}
              style={{ opacity: isPrev ? 1 : 0.5 }}
              onClick={this.jumpPrev}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              disabled={!isNext}
              style={{ opacity: isNext ? 1 : 0.5 }}
              onClick={this.jumpNext}
            >
              <KeyboardArrowRight />
            </IconButton>
          </div>
          <Double style={{ marginLeft: "auto" }}>
            <Button
              variant="contained"
              color="primary"
              disabled={!this.state.isSelected ||
                        !this.props.isAnalyzable(this.state.selectedObject)}
              onClick={()=>this.setState({confirmOpen: true, text: "を分析用データとしてロードしますか？", method: this.analyze })}
            >
              Load
            </Button>
            <Button
              variant="contained"
              color="secondary"
              disabled={!this.state.isSelected}
              style={{ marginLeft: "1em" }}
              onClick={()=>this.setState({confirmOpen: true, text: "を削除しますか？", method: this.delete})}
            >
              Delete
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ marginLeft: "1em" }}
              disabled={!this.state.isSelected}
              onClick={this.download}
            >
              Download
            </Button>
          </Double>
        </div>
        {iconView}
        {finderModeToggle}
        <ConfirmDialog
          useStyles={useStyles}
          open={this.state.confirmOpen}
          file={this.state.file}
          text={this.state.selectedObject.name + this.state.text}
          onCancel={() => {
            this.setState({ confirmOpen: false , isSelected: false, selectedObject: {name: "", type: ""},});
          }}
          onOk={
            this.state.method
          }
        />
        <TextEntryDialog
          useStyles={useStyles}
          open={this.state.directoryNameDialogOpen}
          file={this.state.file}
          placeholder="新しいフォルダ名を入力してください"
          onCancel={() => {
            this.setState({ directoryNameDialogOpen: false, });
          }}
          onSubmit={this.handleCreateDirectory}
        />
      </div>
    );
  }
}

export default FolderReader;
