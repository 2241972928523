import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import { Double } from "csv-viewer-react";
import React from "react";

function ConfirmDialog(props) {
  const classes = props.useStyles();
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <p>{props.text}</p>
            <Double>
              <Button
                variant="contained"
                color="primary"
                onClick={props.onOk}
              >
                OK
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
            </Double>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default ConfirmDialog;
