import React, { useReducer, useEffect } from "react";
import { createStyles, makeStyles } from "@material-ui/core/styles";

import TextField from "@material-ui/core/TextField";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import CardHeader from "@material-ui/core/CardHeader";
import Button from "@material-ui/core/Button";
import User from "./User";

import { withRouter } from "react-router-dom";

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: "flex",
      flexWrap: "wrap",
      width: 400,
      margin: `${theme.spacing(0)} auto`,
    },
    loginBtn: {
      marginTop: theme.spacing(2),
      flexGrow: 1,
    },
    header: {
      textAlign: "center",
      background: "#212121",
      color: "#fff",
    },
    card: {
      marginTop: theme.spacing(10),
    },
  })
);

const initialState = {
  id: "",
  password: "",
  isButtonDisabled: true,
  helperText: "",
  isError: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "setUserEmail":
      return {
        ...state,
        id: action.payload,
      };
    case "setPassword":
      return {
        ...state,
        password: action.payload,
      };
    case "setIsButtonDisabled":
      return {
        ...state,
        isButtonDisabled: action.payload,
      };
    case "loginSuccess":
      return {
        ...state,
        helperText: action.payload,
        isError: false,
      };
    case "loginFailed":
      return {
        ...state,
        helperText: action.payload,
        isError: true,
      };
    case "setIsError":
      return {
        ...state,
        isError: action.payload,
      };
    default:
      return state;
  }
};

const Login = (props) => {
  const classes = useStyles();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (state.id.trim() && state.password.trim()) {
      dispatch({
        type: "setIsButtonDisabled",
        payload: false,
      });
    } else {
      dispatch({
        type: "setIsButtonDisabled",
        payload: true,
      });
    }
  }, [state.id, state.password]);

  // TODO : 非同期処理がおかしいのをなんとかする．1回目のhandleLoginではUser.isLoggedIn()はfalseのままで，2回目になってからtrueになってしまう．
  const handleLogin = async () => {
    const user = await User.login(state.id, state.password);
    if (User.isLoggedIn()) {
      dispatch({
        type: "loginSuccess",
        payload: "Login Successfully",
      });
      props.history.push({
        pathname: "main",
        state: {
          userID: user.userID,
          userName: user.userName,
          userEmail: user.userEmail,
          projects: user.projects,
        },
      });
    } else {
      dispatch({
        type: "loginFailed",
        payload: "Incorrect ID or Password",
      });
    }
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
      state.isButtonDisabled || handleLogin();
    }
  };

  const handleUserEmailChange = (event) => {
    dispatch({
      type: "setUserEmail",
      payload: event.target.value,
    });
  };

  const handlePasswordChange = (event) => {
    dispatch({
      type: "setPassword",
      payload: event.target.value,
    });
  };
  return (
    <form className={classes.container} noValidate autoComplete="off">
      <CardHeader className={classes.header} title="PEFC Data Viewer" />
      <Card className={classes.card}>
        <CardContent>
          <TextField
            error={state.isError}
            fullWidth
            id="id"
            type="email"
            label="ID"
            placeholder="ID"
            margin="normal"
            variant="outlined"
            onChange={handleUserEmailChange}
            onKeyPress={handleKeyPress}
          />
          <TextField
            error={state.isError}
            fullWidth
            id="password"
            type="password"
            label="Password"
            placeholder="Password"
            margin="normal"
            variant="outlined"
            helperText={state.helperText}
            onChange={handlePasswordChange}
            onKeyPress={handleKeyPress}
          />
          {/* <div>
          </div> */}
        </CardContent>
        <CardActions>
          <Button
            variant="contained"
            size="large"
            color="primary"
            className={classes.loginBtn}
            onClick={handleLogin}
            disabled={state.isButtonDisabled}
          >
            Login
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};

export default withRouter(Login);
