import axios from "axios";

class User {
  isLoggedIn = () => !!localStorage.getItem("token");

  set = (key, value) => sessionStorage.setItem(key, value);

  get = (key) => this.getSessionStorage(key);

  getSessionStorage = (key) => {
    const ret = sessionStorage.getItem(key);
    if (ret) {
      return ret;
    }
    return null;
  };

  login = async (email, password) => {
    const apiURL = process.env.REACT_APP_API_URL;
    let params = new URLSearchParams();
    params.append("username", email);
    params.append("password", password);
    let token = "";
    let userID = "";
    let userName = "";
    let userEmail = "";
    let projects = [];
    try {
      const results = await axios.post(apiURL + "/token", params);
      if (results.status !== 200)
        console.log("unknown error. results:", results);
      console.log("results:", results);
      token = results.data.access_token;
      userID = results.data.user_id;
      userName = results.data.user_name;
      userEmail = results.data.user_email;
      projects = results.data.projects;
      // this.set("isLoggedIn", true);
    } catch (error) {
      const { status, statusText } = error.response;
      console.log(`Error! HTTP Status: ${status} ${statusText}`);
      // this.set("isLoggedIn", false);
    }
    localStorage.setItem("token", token);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userID", userID);
    localStorage.setItem("project", projects[0].project_name);
    localStorage.setItem("projects", JSON.stringify(projects));
    return {
      userID: userID,
      userEmail: userEmail,
      userName: userName,
      projects: projects,
    };
  };

  logout = async () => {
    if (this.isLoggedIn()) {
      // this.set("isLoggedIn", false);
      localStorage.setItem("token", "");
    }
  };
}

export default new User();
