import React from "react"
import { makeStyles } from "@material-ui/core";
import { useDrag } from "react-dnd";
import FileIcon from "./FileIcon";

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    userSelect: 'none',
    padding: '.125rem',
    paddingRight: '.25rem',
    paddingLeft: '.25rem',
    '&:hover': {
      background: "rgba(0, 255, 0, 0.1)",
      cursor: 'pointer',
    },
  },
  selected: {
    backgroundColor: "rgba(0, 255, 0, 0.5)",
    '&:hover': {
      background: "rgba(0, 255, 0, 0.7)",
      cursor: 'pointer',
    },
  },
  opened: {
    backgroundColor: "rgba(0, 255, 0, 0.25)",
    '&:hover': {
      background: "rgba(0, 255, 0, 0.45)",
      cursor: 'pointer',
    },
  }
}));

const FinderItem = ({isSelected, isHighlighted, fileName, fileType,
  onClick, address }) => {
  const { card, selected, opened, } = useStyles();

  const [, drag] = useDrag(() => ({
    type: "finderItem",
    item: {fileName, address},
    options: {dropEffect: "move"},
  }), [fileName, address]);

  const style = isSelected
  ? selected
  : isHighlighted
    ? opened
    : null;
  return (
    <div
      ref={drag}
      key={fileName}
      className={`${card} ${style}`}
      onClick={onClick}
    >
      <FileIcon
        fileName={fileName}
        fileType={fileType}
      />
      <span>
        {fileName}
      </span>
    </div>
  );
}

export default FinderItem;
