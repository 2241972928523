import React, { useEffect } from "react";
import { handler } from "../../utils";
import { DoublePlot } from "csv-viewer-react";
import Double from "csv-viewer-react/dist/components/templates/Double";
import Loading from "../templates/Loading";

const API_VERSION = process.env.REACT_APP_API_VERSION

const HDFPlots = props =>  {
  const [data, setData] = React.useState();

  useEffect(() => {
    const asyncFunction = async () => {
      setData(null);
      let response = {};
      if (props.hdfAPI) {
        response = await props.axios.post(
          encodeURI("/analyze-hdf/v" + API_VERSION + "/" + props.currentProject),
          {"directory": props.hdfAPI, "mode": props.mode}
        ).catch(error => handler(error));
        console.log("hdf data : ", response?.data?.data);
      }

      if (!response?.data) return {};
      setData(response.data.data);
    }
    asyncFunction();
    // props.axiosを依存性に含むと、render毎に実行されてしまう
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.mode, props.hdfAPI])

  if (props.hdfAPI && !data) {
    return (
        <Loading/>
    );
  }
  const plotData = [];
  const layout = {
    width: 440,
    height: 440,
    showlegend: false,
    title: { text: props.mode },
    legend: {
      font: { size: 9 },
      orientation: "h",
      y: -0.25,
    },
    hovermode: "closest",
  }
  if (data) {
    const names = data.name ? data.name : [];
    const xdata = data.xdata ? data.xdata : [];
    const ydata = data.ydata ? data.ydata : [];
    for (let i = 0; i < xdata.length; i++) {
      const trace = {
        x: xdata[i],
        y: ydata[i],
        mode: "markers",
        type: "scatter",
        name: names.length > 0 ? names[i] : "",
        xaxis: "x",
        yaxis: "y",
      };
      plotData.push(trace);
    }
    layout.showlegend = names.length > 0;
    layout.xaxis = { title: data.xlabel ? data.xlabel : "" };
    layout.yaxis = { title: data.ylabel ? data.ylabel : "" };
  }
  const config = {
    responsive: true,
    editable: true,
    scrollZoom: true,
    displaylogo: false,
    modeBarButtonsToRemove: ["select2d", "lasso2d", "zoomIn2d", "zoomOut2d"],
  };
  return (
    <>
      <Double>
        <DoublePlot
          data={plotData}
          layout={layout}
          config={config}
        />
      </Double>
    </>
  );
}

export default HDFPlots;
