import { Backdrop, Button, Fade, Modal, TextField } from "@material-ui/core";
import React from "react";
import { Double } from "csv-viewer-react"

function TextEntryDialog(props) {
  const classes = props.useStyles();
  const [value, setValue] = React.useState("");

  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <p>{props.text}</p>
            <div
              style={{paddingBottom:'1rem'}}
            >
              <TextField
                type="text"
                placeholder={props.placeholder}
                onChange={(event) => {
                  setValue(event.target.value);
                }}
              />
            </div>
            <Double>
              <Button
                variant="contained"
                color="primary"
                onClick={() => props.onSubmit(value)}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.onCancel}
              >
                Cancel
              </Button>
            </Double>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

export default TextEntryDialog;
