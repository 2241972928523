import React from "react";
import { Backdrop, Button, Fade, Modal } from "@material-ui/core";
import { DataGrid } from "@mui/x-data-grid";
import ProgressButton from "../templates/ProgressButton";
import ColumnNameModal from "./ColumnNameModal";

const FileListModal = (props) => {
  const [columnNameModalOpen, setColumnNameModalOpen] = React.useState(false);
  const classes = props.useStyles();
  const columns = [
    {
      field: 'file_name',
      headerName: 'Filename',
      width: 600,
    },
  ].concat(props.entries.length > 0 ?
    [...new Set(props.entries.map(entry =>
      Object.keys(entry)
      .filter(key => key !== 'file_name'))
      .reduce((s, q) => s.concat(q)))]
      .map(key => ({
        field: key,
        headerName: key,
        description: key,
        sortable: false,
        width: 100,
        editable: true,
      })) :
    []
  );

  const rows = props.entries.map((entry, index) => ({
    ...entry,
    id: index,
  }));

  return (
    <div>
      <Modal
        aria-labelledby="modal-title"
        className={classes.modal}
        open={props.open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.open}>
          <div className={classes.paper}>
            <h2 id="modal-title">File list</h2>
            <div style={{ height: '70vh', width: '90vw' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                checkboxSelection
                disableSelectionOnClick
                onCellEditCommit={props.onFileListCellEdit}
                onSelectionModelChange={props.onFileListSelectionChange}
              />
            </div>
            <div className={classes.buttonRoot}>
              <ProgressButton
                postData={() => props.onSubmit(props.entries)}
                modalClose={props.modalClose}
              />
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="secondary"
                  // className={buttonClassname}
                  onClick={props.modalClose}
                >
                  Cancel
                </Button>
              </div>
              <div className={classes.wrapper}>
                <Button
                  variant="contained"
                  color="primary"
                  // className={buttonClassname}
                  onClick={() => setColumnNameModalOpen(true)}
                >
                  Add column
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <ColumnNameModal
        open={columnNameModalOpen}
        setOpen={setColumnNameModalOpen}
        onAddColumn={props.onAddColumn}
        useStyles={props.useStyles}
      />
    </div>
  );
}

export default FileListModal;
