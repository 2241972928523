import { makeStyles } from "@material-ui/core";
import React from "react";
import IconViewItem from "./IconViewItem";

const useStyles = makeStyles((theme) => ({
  dropZone: {
    position: 'relative',
    width: 'calc(90vw - 240px)',
    minWidth: '24rem',
    minHeight: '70vh',
    border: 'dashed 5px skyblue',/* ラベルのボーダーを指定する */
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'left',
    justifyContent: 'flex-start',
    alignContent: 'flex-start',
    paddingTop: '2.6rem',
  }
}));

function IconView({directory, selectedObject, isSelected, currentProject,
    onDragOn, onDrop, onDoubleClick, onClick, onMove, onCreateFolder, address}) {

  const { dropZone } = useStyles();

  const icons = directory.map(file =>
    <IconViewItem
      key={file.name}
      fileName={file.name}
      fileType={file.type}
      address={address}
      isSelected={isSelected && (selectedObject === file)}
      onDoubleClick={() => onDoubleClick(address, file)}
      onClick={() => onClick(address, file)}
      onMove={onMove}
    />
  );

  return(
    <div
      onDragOver={onDragOn}
      onDrop={onDrop}
      className={dropZone}
    >
      {icons}
      <IconViewItem
        isSelected={false}
        fileName="新規フォルダを作成..."
        fileType={"newFolder"}
        address={address}
        onClick={() => onCreateFolder(address)}
      />
    </div>
  )
}

export default IconView;
