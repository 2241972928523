import React, { Component } from 'react';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { DoublePlot } from 'csv-viewer-react';
import Double from 'csv-viewer-react/dist/components/templates/Double';

class Correlation extends Component {
  constructor(props){
    super(props)
    this.state = {
      xLabel:Object.keys(props.record.filteredData).length>0 ? props.record.xLabel : "X Label",
      yLabel:Object.keys(props.record.filteredData).length>0 ? props.record.yLabel : "Y Label",
      file: undefined,
    }
  }

  skip = data => {
    const N = data[0].length
    const newData = []
    for(const d of data){
      const tempData = []
      for(let n = 0; n < N; n++){
        if(this.props.skipRecord[n]){ 
          continue
        }
        tempData.push(d[n])
      }
      newData.push(tempData)
    }
    return newData
  }

  render(){
    const record = this.props.record
    const listofCorr = Object.keys(record.filteredData)
    const dataC = []
    for(let i = 0; i < listofCorr.length; i++){
        const c = this.props.categoryMap[listofCorr[i]]
        if(!(record.categoryFilter[c]&&record.labelFilter[listofCorr[i]])){
            continue
        }
        const trace = {
            x: [record.corr[this.props.xLabel][listofCorr[i]]],
            y: [record.corr[this.props.yLabel][listofCorr[i]]],
            text: [listofCorr[i]],
            name:listofCorr[i],
            textposition: 'bottom left',
            hoverinfo: 'x+y+text',
            hovertemplate:
            "<b>%{text}</b><br>" +
            "%{yaxis.title.text}: %{y}<br>" +
            "%{xaxis.title.text}: %{x}<br>" +
            "<extra></extra>",
            mode: 'markers+text',
            type: 'scatter',
            marker:{
              color : c,
              opacity:0.8,
              size:10
            },
            xaxis: 'x',
            yaxis: 'y'
        }
        dataC.push(trace)
    }
    const layoutS = {
      width:440, 
      height: 440,
      showlegend: false,
      title: {text: 'Scatter Plot'},
      hovermode: 'closest',
      xaxis:{title: this.props.xLabel},
      yaxis:{title: this.props.yLabel}
    }
    const dataS = [{
      x: record.filteredData[this.props.xLabel],
      y: record.filteredData[this.props.yLabel],
      mode: 'markers',
      type: 'scatter',
      name:'',
      marker:{size:7.5, color:'orange'}
    }]
    const configS = {
      responsive: true, 
      editable: true, 
      scrollZoom: true,
      displaylogo: false, 
      modeBarButtonsToRemove: ['select2d', 'lasso2d', 'zoomIn2d', 'zoomOut2d'],
    }
    const options = Object.keys(this.props.record.filteredData).map( key => {
      return (<option key={key} value={key}>{key}</option>)
    })
    return(
      <>
        <Double>
          <DoublePlot
            data={dataS}
            layout={layoutS}
            config={configS}
          />
        </Double>
        <div></div>
        <Double>
          <FormControl>
            <Select
              native
              value={this.props.xLabel}
              onChange={event => this.props.handleXLabelChange(event.target.value)}
            >
              <option value={""} disabled>X Label</option>
              {options}
            </Select>
          </FormControl>
          <FormControl>
            <Select
              native
              value={this.props.yLabel}
              onChange={event => this.props.handleYLabelChange(event.target.value)}
            >
              <option value={""} disabled>Y Label</option>
              {options}
            </Select>
          </FormControl>
        </Double>
      </>
    )
  }
};

export default Correlation;