import React, { Component } from "react";
import FolderReader from "../reader/FolderReader";
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { handler } from "../../utils";
import { generateFileList, uploadAndGenerateHdf, uploadOnly } from "../../modules/APIutils";
import MemoModal from "./MemoModal";
import { IconButton, makeStyles, Snackbar } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close"
import FileListModal from "./FileListModal";
import Double from "csv-viewer-react/dist/components/templates/Double";

const API_VERSION = process.env.REACT_APP_API_VERSION

const useStyles = makeStyles((theme) => ({
  buttonRoot: {
    display: 'flex',
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  wrapper: {
    margin: theme.spacing(1),
    position: 'relative',
  },
}));

class Importer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: undefined,
      modalOpen: false,
      fileListModalOpen: false,
      snackbarOpen: false,
      fileListEntries: [],
      selectedFileListEntries: [],
    };
    this.folderReaderRef = React.createRef();
  }

  isAnalyzable = file => {
    return (file.type==="csv" && file.name !== "file_list.csv") ||
      file.type==="h5";
  }

  analyzeFile = async (directory, fileName, fileType) => {
    console.log("analyze");
    if (fileType === 'csv') {
      console.log("csv");
      const params = {
        directory,
        file_name: fileName,
        file_type: fileType,
      }
      await this.props.axios.get(
        encodeURI(
          "/download-file/v" + API_VERSION + "/" +
            this.props.currentProject
        ),
        { params, responseType: 'text' }
      ).then(async result => {
        this.props.handleDataFileChange(result.data)
      }).catch(error => handler(error));
    } else if (fileType === 'h5') {
      const api = directory + fileName
      console.log("hdf api : ", api)
      this.props.handleHDFAPI(api)
    }

  }

  handleDrop = async (event, address) => {
    event.preventDefault();
    const entry = event.dataTransfer.items[0].webkitGetAsEntry();
    if (!!entry) {
      const root = address + entry.name.split(".")[0] + "__" + Date.now().toString()
      console.log("root", root);
      const root_raw = root + "/raw_data/";
      console.log(entry);

      const [uploadFiles, fileListEntries] =
        await generateFileList(entry, root_raw);

      if (fileListEntries) {
        this.setState({
          root,
          modalOpen: true,
          snackbarOpen: false,
          uploadFiles,
          fileListEntries,
        });
      } else {
        console.log("uploading", this.props.currentProject);
        await uploadOnly(this.props.axios, this.props.currentProject, uploadFiles, address);
        await this.folderReaderRef.current.refreshDirectories();
        this.setState({
          snackbarOpen: false,
        });
      }
    }
  };

  handleMetadataSubmit = (metadata) => {
    this.setState({
      metadata,
      fileListModalOpen: true,
      selectedFileListEntries: [],
    });
  };

  handleAddFileListColumn = columnName => {
    const newEntries = this.state.fileListEntries.map(entry =>
      ({
        ...entry,
        [columnName]: "",
      })
    );
    this.setState({
      fileListEntries: newEntries,
    });
  }

  handleFileListSelectionChanged = model => {
    this.setState({
      selectedFileListEntries: model,
    })
  }

  handleFileListCellEdit = ({ id, field, value }) => {
    const oldEntries = this.state.fileListEntries;
    const selected = this.state.selectedFileListEntries;
    const idsToEdit = selected.includes(id) ?
      selected : [id];
    const newEntries = oldEntries.map((entry, index) =>
      idsToEdit.includes(index) ?
        {
          ...entry,
          [field]: value
        } :
        { ...entry }
    );
    this.setState({
      fileListEntries: newEntries,
    });
  }

  handleFileListSubmit = async(newFileListEntries) => {
    const { metadata, root, uploadFiles } = this.state;
    try {
      const [hdfResponse, metadataResponse] =
        await uploadAndGenerateHdf(this.props.axios, this.props.currentProject, root,
          metadata, uploadFiles, newFileListEntries);
      this.props.handleHDFAPI(hdfResponse.data.hdf_path);
      await this.folderReaderRef.current.refreshDirectories();
      this.setState({
        snackbarOpen: true,
        fileId: metadataResponse.data.file_id
      });
    } catch (error) {
      handler(error);
    }
  }

  snackbarClose = (_event, reason) => {
    if (reason !== "clickaway") {
      this.setState({snackbarOpen:false});
    }
  }

  render() {
    console.log("current project", this.props.currentProject);
    return (
      <DndProvider backend={HTML5Backend}>
        <Double>
          <FolderReader
            ref={this.folderReaderRef}
            useStyles={useStyles}
            fileID="Data"
            title="Data File"
            analyzeFile={this.analyzeFile}
            isAnalyzable={this.isAnalyzable}
            userID={this.props.userID}
            userName={this.props.userName}
            projects={this.props.projects}
            finame={this.props.dataFiname}
            handleProjectChange={this.props.handleProjectChange}
            currentProject={this.props.currentProject}
            handleDrop={this.handleDrop}
            axios={this.props.axios}
          />
        </Double>
        <MemoModal
          useStyles={useStyles}
          open={this.state.modalOpen}
          postData={this.handleMetadataSubmit}
          modalClose={() => {
            this.setState({ modalOpen: false });
          }}
        />
        <FileListModal
          useStyles={useStyles}
          open={this.state.fileListModalOpen}
          entries={this.state.fileListEntries}
          onFileListCellEdit={this.handleFileListCellEdit}
          onFileListSelectionChange={this.handleFileListSelectionChanged}
          onAddColumn={this.handleAddFileListColumn}
          onSubmit={this.handleFileListSubmit}
          modalClose={() => {
            this.setState({ fileListModalOpen: false });
          }}
        />
        <Snackbar
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.snackbarOpen}
          // autoHideDuration={6000}
          onClose={this.snackbarClose}
          message={`アップロードが完了しました。ファイルID：${this.state.fileId}`}
          action={
            <>
              <IconButton size="small" aria-label="close" color="inherit"
                onClick={this.snackbarClose}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </>
          }
        />
      </DndProvider>
    );
  }
}

export default Importer;
